<template>
  <div>
    <p class="font-medium capitalize">{{ title.replaceAll('_', ' ') }}</p>
    <div
      class="mt-5 border overflow-x-auto md:overflow-x-hidden border-primary-grey rounded-lg pb-0.5"
    >
      <table class="w-full table-auto whitespace-nowrap">
        <thead class="bg-bright-gray">
          <tr class="text-left">
            <th
              v-for="(head, ind) in tableHeadArray"
              :key="ind"
              class="py-5 font-medium rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
            >
              <span
                v-i18n="settings"
                class="ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
              >
                {{ head }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(subModule, index) in modules"
            :key="index"
            class="border-t border-primary-grey text-left"
          >
            <td class="rtl:border-l ltr:border-r border-primary-grey rtl:text-right px-4 py-5">
              <span class="font-roboto font-normal text-text-color capitalize text-sm">
                {{ index.replaceAll('_', ' ') }}
              </span>
            </td>
            <td
              v-for="(value, i) in subModule"
              :key="`sub-module${i}`"
              class="rtl:border-l ltr:border-r md:w-40 border-primary-grey rtl:text-right px-3"
            >
              <div v-if="!value.not_applicable" class="flex items-start">
                <UiCheckBox
                  :key="`forceRenderCheckbox${forceRender}-${value.id}`"
                  :model-value="value.is_permissible"
                  :halt-update="value.is_locked"
                  @triggerChange="updateSetting(value, 'checkbox')"
                />
                <span
                  class="block box-border h-6 border_width border-primary-grey ltr:mr-4 rtl:mx-2 visible"
                ></span>
                <UiLockButton
                  :key="`forceRender${forceRender}-${value.id}`"
                  :lock-value="value.is_locked"
                  :deactivate="value.is_locked_at_upper_level"
                  v-bind="value"
                  @updateLockEvent="updateSetting(value, 'lock')"
                />
              </div>
              <div v-else class="flex">
                <span class="text-4xl flex items-center text-gray-600 h-6">--</span>
                <span
                  class="block box-border h-6 border_width border-primary-grey ltr:mx-2 rtl:mx-2 visible"
                ></span>
                <span class="text-4xl flex items-center text-gray-600 h-6">--</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* 3rd Party Apis */
import { mapActions } from 'vuex'
/* COMPONENTS */
import UiLockButton from '@src/components/UiElements/UiLockAnimation.vue'
import UiCheckBox from '@src/components/UiElements/UiCheckbox.vue'
/* UTILS */
import { objectDeepCopy, removeEmptyKeysFromObject } from '@utils/generalUtil'

export default {
  components: {
    UiCheckBox,
    UiLockButton,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    title: {
      type: String,
      default: 'Role',
    },
    parentModule: {
      type: String,
      default: '',
    },
    tableHeadArray: {
      type: [Array, Object],
      default: () => [],
    },
    modulesArray: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  emits: ['updateSettings'],
  data: function () {
    return {
      isLocked: '',
      modules: objectDeepCopy(this.modulesArray),
      settings: 'settings',
      forceRender: 0,
    }
  },
  mounted() {
    const order = {
      filter: 1,
      receive: 2,
      create: 3,
      update: 4,
      deactivate: 5,
    }
    setTimeout(() => {
      for (const [key, value] of Object.entries(this.modules)) {
        value.sort((a, b) => order[a.action] - order[b.action])
      }
    })
  },
  /* getting the state of lock from child using emit and assiging it to a data property */

  methods: {
    async updateSetting(value, type) {
      let res, err
      removeEmptyKeysFromObject(value)
      this.updateObject(value, type)
      delete value.id
      switch (this.parentModule) {
        case 'fee_types': {
          ;[res, err] = await this.updateFeePermissionSettings(value)
          break
        }
        case 'attendance': {
          ;[res, err] = await this.updateAttandaceSettings(value)
          break
        }
        case 'syllabus': {
          ;[res, err] = await this.updateSyllabusPermissionSettings(value)
          break
        }
        case 'salary': {
          ;[res, err] = await this.updateSalaryPermissionSettings(value)
          break
        }
        case 'schedule': {
          ;[res, err] = await this.updateSchedulePermissionSettings(value)
          break
        }
        case 'lesson-planner': {
          ;[res, err] = await this.updateLessonPlannerPermissionSettings(value)
          break
        }
        case 'exam': {
          ;[res, err] = await this.updateExamPermissionSettings(value)
          break
        }
        case 'pickup': {
          ;[res, err] = await this.updatePickupPermissionSettings(value)
          break
        }
      }
      if (res) {
        this.$emit('updateSettings')
      } else {
        this.updateObject(value, type)
      }
      value.id = Math.random() * 100000
      this.forceRender++
    },
    updateObject(object, type) {
      switch (type) {
        case 'checkbox': {
          object.is_permissible = !object.is_permissible
          break
        }
        case 'lock': {
          object.is_locked = !object.is_locked
          break
        }
      }
    },
    /* ACTIONS */
    ...mapActions('settings', [
      'updateAttandaceSettings',
      'updateFeePermissionSettings',
      'updateSyllabusPermissionSettings',
      'updateSalaryPermissionSettings',
      'updateSchedulePermissionSettings',
      'updateLessonPlannerPermissionSettings',
      'updateExamPermissionSettings',
      'updatePickupPermissionSettings',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.border_width {
  border-width: 0.5px;
}
</style>
